import(/* webpackMode: "eager", webpackExports: ["HasRecipesGenerated","Section","SuggestedTokenBadge","Container","SuggestedIngredientsSection","SuggestedTagsSection","IsTakingLongerThanUsual","HintCarousel","SuggestedRecipeCards"] */ "/vercel/path0/src/app/@craft/components.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Badge"] */ "/vercel/path0/src/components/display/badge.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Label"] */ "/vercel/path0/src/components/display/label.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/feedback/ellipsis.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/keyboard-avoiding-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/util/client-only.tsx");
